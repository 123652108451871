import type { Chatroom, Message } from '@/declarations/channel.d';
import useModal from '@/hooks/useModal';
import {
  getChatrooms,
  getContactStatus,
  markChatroomArchiveStatus,
  markChatroomAsPin,
  markChatroomAsRead,
  markChatroomAsUnRead,
} from '@/services/luluchat/channels';
import { getContacts } from '@/services/luluchat/contacts';
import { getErrorMessages } from '@/services/luluchat/request';
import type { API } from '@/services/luluchat/typings.d';
import type { FormInstance } from 'antd';
import { message, notification } from 'antd';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { useCallback, useRef, useState } from 'react';
import { history, useModel, useRequest } from 'umi';

export default () => {
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isLoadingSearchMessages, setIsLoadingSearchMessages] = useState<boolean>(false);
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const {
    setMessages,
    onLoadMessages,
    setCurrentHighlightMessage,
    setHasNewMessageDuringDisableState,
    setIsDisableLiveMessages,
    goToLatestMessage,
  } = useModel('messenger');
  const [lastSearchMessageKey, setLastSearchMessageKey] = useState('');
  const [globalSearchType, setGlobalSearchType] = useState<'contacts' | 'messages'>('contacts');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState<string>('');
  const [selectedChat, setSelectedChat] = useState<Chatroom | null>(null);
  const [chats, setChats] = useState<Chatroom[]>([]);
  const [isOpenChatList, setIsOpenChatList] = useState<boolean>(false);
  const [isShowNewUpdatesOnChatList, setIsShowNewUpdatesOnChatList] = useState<boolean>(false);
  const [isLiveUpdateChatlist, setIsLiveUpdateChatlist] = useState<boolean>(true);
  const [advancedSection, setAdvancedSection] = useState<'search' | 'contact'>('contact');
  const [currentEditNote, setCurrentEditNote] = useState<Message | null>(null);
  const [hasActiveConversationWindow, setHasActiveConversationWindow] = useState<boolean>(false);
  const [chatroomFilters, setChatroomFilters] = useState<{
    inbox_tab?: number;
    search?: string;
    [key: string]: any;
  }>({});
  const globalSearchFormRef = useRef<FormInstance>(null);
  const [isShowAdvancedActions, setIsShowAdvancedActions] = useState<boolean>(
    localStorage.getItem('is-show-advanced-actions') === 'true' || false,
  );
  const { fetchCreateContact } = useModel('contact');
  const { run: onFetchGetChatrooms, loading: isLoadingFetchGetChatrooms } = useRequest(
    getChatrooms,
    {
      manual: true,
    },
  );

  const fetchGetChatrooms = async (props?: {
    search_id?: string;
    search?: string;
    isAppend?: boolean;
    mention?: [string | number];
    assignment?: [string | number];
    collaborator?: [string | number];
    tag?: [number];
    inbox_tab?: number;
    wa_contact_id?: string;
    from?: number;
    limit?: number;
  }) => {
    const {
      isAppend = false,
      search,
      search_id,
      mention,
      assignment,
      collaborator,
      tag,
      inbox_tab,
      wa_contact_id,
      from,
      limit,
    } = props || {};
    const payload = {} as {
      search: string;
      search_id?: string;
      'filter_by[inbox_tab]': number;
      'filter_by[mention]'?: string;
      'filter_by[assignment]'?: string;
      'filter_by[collaborator]'?: string;
      'filter_by[tag]'?: string;
      'filter_by[wa_contact_id]'?: string;
      from?: number;
      limit?: number;
    };
    if (limit) {
      payload.limit = limit;
    }
    if (search) {
      payload.search = search;
    }
    if (search_id) {
      payload.search_id = search_id;
    }
    if (collaborator && collaborator.length > 0) {
      payload['filter_by[collaborator]'] = collaborator.join(',');
    }
    if (mention && mention.length > 0) {
      payload['filter_by[mention]'] = mention.join(',');
    }
    if (inbox_tab) {
      payload['filter_by[inbox_tab]'] = inbox_tab;
    }
    if (wa_contact_id) {
      payload['filter_by[wa_contact_id]'] = wa_contact_id;
    }
    if (assignment && assignment.length > 0) {
      payload['filter_by[assignment]'] = assignment.join(',');
    }
    if (tag && tag.length > 0) {
      payload['filter_by[tag]'] = tag.join(',');
    }
    if (from) {
      payload.from = from;
    }
    const res = await onFetchGetChatrooms(payload);
    if (res && Array.isArray(res) && res?.length > 0) {
      if (isAppend) {
        setChats((prevChats) => [...prevChats, ...res]);
      } else {
        setChats(res);
      }
    } else if (!isAppend) {
      setChats([]);
    }
    return res;
  };

  const throttleReloadFetchGetChatroom = _throttle(
    async ({ from, limit, filters }: { from: number; limit: number; filters: any }) => {
      if (typeof fetchGetChatrooms === 'function') {
        await fetchGetChatrooms({ ...filters, limit, from });
      }
    },
    1000,
  );

  const reloadFetchGetChatroom = useCallback(
    (from: number, limit?: number) => {
      setGlobalSearchKeyword((prev) => {
        if (!!prev) {
          // onReloadSearchChatroom();
          return prev;
        }
        setChatroomFilters((prevChatroomFilters) => {
          throttleReloadFetchGetChatroom({
            from,
            limit: limit || 15,
            filters: prevChatroomFilters,
          });
          return prevChatroomFilters;
        });
        return prev;
      });
    },
    [chatroomFilters],
  );

  const { run: fetchMarkChatroomAsRead, loading: isLoadingFetchMarkChatroomAsRead } = useRequest(
    markChatroomAsRead,
    {
      manual: true,
      formatResult(res): API.MarkChatroomAsReadResult {
        return res;
      },
    },
  );
  const { run: fetchMarkChatroomAsUnRead, loading: isLoadingFetchMarkChatroomAsUnRead } =
    useRequest(markChatroomAsUnRead, {
      manual: true,
      formatResult(res): API.MarkChatroomAsUnReadResult {
        return res;
      },
    });
  const { run: fetchMarkChatroomArchiveStatus, loading: isLoadingFetchMarkChatroomArchiveStatus } =
    useRequest(markChatroomArchiveStatus, {
      manual: true,
      formatResult(res): API.MarkChatroomAsUnReadResult {
        return res;
      },
    });

  const { run: fetchMarkChatroomAsPin, loading: isLoadingFetchMarkChatroomAsPin } = useRequest(
    markChatroomAsPin,
    {
      manual: true,
      formatResult(res): API.MarkChatroomAsPinResult {
        return res;
      },
    },
  );

  const refreshTotalCount = async (id: string) => {
    setChats((prevChats: Chatroom[]) => {
      let _chats: Chatroom[] = [];
      if (prevChats && prevChats.length > 0) {
        _chats = prevChats.slice();
        const index = prevChats.findIndex((chat) => id === chat.id);
        if (index > -1) {
          _chats[index].total_unread = 0;
          return _chats;
        }
      }
      return _chats;
    });
  };

  const onMarkChatroomAsUnarchive = async (wa_contact_id: string) => {
    const res = await fetchMarkChatroomArchiveStatus({
      wa_contact_id,
      status: 0,
    });
    if (res.status) {
      reloadFetchGetChatroom(10);
    }
  };

  const onMarkChatroomAsArchive = async (wa_contact_id: string) => {
    const res = await fetchMarkChatroomArchiveStatus({
      wa_contact_id,
      status: 1,
    });
    if (res.status) {
      reloadFetchGetChatroom(10);
    }
  };

  const onSearchChatrooms = async ({
    search,
    ...props
  }: {
    search: string;
    search_id?: string;
  }) => {
    return await onFetchGetChatrooms({
      ...props,
      search: search,
    });
  };

  const onGetChatrooms = async (newFilters: any) => {
    setChats([]);
    return await fetchGetChatrooms({
      ...newFilters,
      from: 7,
    });
  };

  const onClearFilters = () => {
    const nextFilters = {
      ...chatroomFilters,
      mention: [],
      assignment: [],
      collaborator: [],
      tag: [],
    };
    setChatroomFilters(nextFilters);
    onGetChatrooms(nextFilters);
  };

  const showChatDetails = async (
    id: string,
    onLoadMessagesParams?: {
      wa_contact_id: string;
      from_message_id?: string | undefined;
    },
  ) => {
    setMessages([]);
    if (!id) return;
    let found: null | Chatroom | undefined = null;
    if (chats && Array.isArray(chats) && chats?.length > 0) {
      found = chats.find((chat) => chat.id === id);
    }
    if (!found) {
      const res = await getChatrooms({
        'filter_by[wa_contact_id]': id,
        include_all: 1,
      });
      if (res?.status && res?.data?.length > 0) {
        found = res?.data[0];
      } else {
        const res2 = await forceRenderChatroomOnCreatedContact(id?.split('@')?.[0]);
        if (res2 === false) {
          history.push({
            pathname: `/inbox/list`,
          });
          return;
        }
        return;
      }
    }
    if (found) {
      setSelectedChat(found);
      if (!!onLoadMessagesParams) {
        await onLoadMessages(onLoadMessagesParams);
      } else {
        await onLoadMessages({ wa_contact_id: found?.id });
      }
      if (found.total_unread > 0 || found.total_unread === -1) {
        const res = await fetchMarkChatroomAsRead({
          wa_contact_id: id,
        });
        if (res.status) {
          refreshTotalCount(id);
        }
      }
      try {
        history.push({
          pathname: `/inbox/${id}`,
        });
      } catch (e) {
        console.error('e', e);
      }
    }
    return found;
  };

  const updateWithLatestChat = (chat: Message) => {
    if (!chat) return;
    let currentContactId: string | null = null;
    let hasFoundOnPreviousList = false;
    setChats((prevChats) => {
      let newChat: Chatroom = {
        id: chat.chatId,
        name: '',
        display_name: '',
        contact_id: 0,
        total_unread: 0,
        is_group: false,
        is_muted: false,
        mute_expiration: 0,
        last_message: {
          text: chat.text,
          timestamp: chat.timestamp,
        },
        assignee: null,
      };
      let _chats = [] as Chatroom[];
      if (prevChats && prevChats.length > 0) {
        _chats = prevChats.slice();
        const index = prevChats.findIndex(({ id }) => id === chat.chatId);
        if (index > -1) {
          const prevChat = prevChats[index];
          let total_unread = 0;
          const pathnameArr = history?.location?.pathname?.split('/');
          if (pathnameArr?.length >= 2) {
            currentContactId = pathnameArr[2];
          }
          if (currentContactId && prevChat?.id === currentContactId) {
            total_unread = prevChat?.total_unread || 0;
          } else if (chat?.fromMe) {
            total_unread = prevChat?.total_unread || 0;
          } else {
            total_unread = (prevChat?.total_unread || 0) + 1;
          }
          newChat = {
            ...prevChat,
            last_message: {
              text: chat.text,
              timestamp: chat.timestamp,
            },
            total_unread,
          };
          _chats.splice(index, 1);
          hasFoundOnPreviousList = true;
          const pinnedChats = _chats.filter(({ is_pinned }) => is_pinned);
          return [...pinnedChats, newChat, ..._chats.slice(pinnedChats.length)];
        }
      }
      return prevChats;
    });
    if (currentContactId === chat.chatId) {
      onMarkAsRead(currentContactId);
    }
    if (!hasFoundOnPreviousList) {
      setTimeout(() => {
        setGlobalSearchKeyword((prevGlobalSearchKeyword) => {
          if (!!prevGlobalSearchKeyword) {
            onReloadSearchChatroom();
          } else {
            setIsLiveUpdateChatlist((prevIsLiveUpdateChatlist) => {
              if (prevIsLiveUpdateChatlist) {
                setIsShowNewUpdatesOnChatList(false);
                reloadFetchGetChatroom(1);
              } else {
                setIsShowNewUpdatesOnChatList(true);
              }
              return prevIsLiveUpdateChatlist;
            });
          }
          return prevGlobalSearchKeyword;
        });
      }, 1000);
    }
  };

  const clearChatDetails = () => {
    setSelectedChat(null);
    setMessages([]);
  };

  const onResetChats = () => {
    setChats([]);
    setIsLiveUpdateChatlist(true);
    setIsShowNewUpdatesOnChatList(false);
  };

  const forceRenderChatroomOnCreatedContact = async (contact_number: string) => {
    const contact = await getContacts({
      page: 1,
      perpage: 10,
      includes: 'assignee',
      iso_contact_number: contact_number,
    });
    let finalSelectedChat: Chatroom = {
      id: `${contact_number}@s.whatsapp.net`,
      name: contact_number,
      display_name: '',
      contact_id: undefined,
      total_unread: 0,
      is_archived: false,
      is_group: false,
      is_muted: false,
      is_pinned: false,
      group_participants: [],
      mute_expiration: null,
      tags: [],
      last_message: {
        text: '',
        timestamp: '',
      },
      assignee: null,
    };
    if (contact && contact?.data?.length > 0) {
      const firstChat = contact?.data[0];
      finalSelectedChat = {
        ...finalSelectedChat,
        contact_id: firstChat?.id,
        assignee: firstChat?.assignee,
        display_name: firstChat?.display_name,
      };
      setSelectedChat(finalSelectedChat);
      try {
        history.push({
          pathname: `/inbox/${contact_number}@s.whatsapp.net`,
        });
      } catch (e) {
        console.error('e', e);
      }
      return finalSelectedChat;
    } else {
      return false;
    }
  };

  const onAddNewChat = async (contactNumber: string) => {
    const contact_number = contactNumber?.startsWith('+')
      ? contactNumber?.replace('+', '')
      : contactNumber;
    const res = await getContactStatus({
      phone_number: contact_number,
    });
    if (res?.status) {
      if (res?.data?.status === 'existed_but_not_permitted') {
        notification.error({
          message: 'Failed to Add New Chat',
          description:
            'This contact has already been added to your main account, but you do not have permission to view it. Please ask the master account owner to grant you permission by assigning you as an assignee or collaborator.',
          duration: 0,
        });
      } else if (res?.data?.status === 'existed') {
        message.info(
          'This contact has already been added previously. Redirecting you to the conversation.',
        );
        const conversation = await showChatDetails(`${contact_number}@s.whatsapp.net`);

        // mean never chat before
        if (!conversation) {
          forceRenderChatroomOnCreatedContact(contact_number);
        }
      } else if (res?.data?.status === 'new') {
        const contactResponse = await fetchCreateContact({
          iso_contact_number: contact_number,
          display_name: '',
          type: 'individual',
          tags: [],
          assignee_id: '',
        });
        if (contactResponse.status && contactResponse?.data?.id) {
          setSelectedChat({
            id: `${contact_number}@s.whatsapp.net`,
            name: contact_number,
            display_name: '',
            contact_id: contactResponse?.data?.id,
            total_unread: 0,
            is_archived: false,
            is_group: false,
            is_muted: false,
            is_pinned: false,
            group_participants: [],
            mute_expiration: null,
            last_message: {
              text: '',
              timestamp: '',
            },
            tags: [],
            assignee: null,
          });
        } else {
          notification.error({
            message: 'Failed to Create Contact during Add New Chat',
            description: getErrorMessages(contactResponse?.errors || contactResponse?.message),
            duration: 0,
          });
        }
      }
    } else {
      message.error(res?.message);
    }
  };

  const onMarkAsPin = async (contact_id: string) => {
    const res = await fetchMarkChatroomAsPin({ wa_contact_id: contact_id, status: 1 });
    if (res?.status) {
      reloadFetchGetChatroom(11);
    }
  };

  const onMarkAsUnPin = async (contact_id: string) => {
    const res = await fetchMarkChatroomAsPin({ wa_contact_id: contact_id, status: 0 });
    if (res?.status) {
      reloadFetchGetChatroom(11);
    }
  };

  const onMarkAsRead = async (contact_id: string) => {
    const res = await fetchMarkChatroomAsRead({ wa_contact_id: contact_id });
    if (res?.status) {
      refreshTotalCount(contact_id);
    }
  };

  const onMarkAsUnRead = async (contact_id: string) => {
    const res = await fetchMarkChatroomAsUnRead({ wa_contact_id: contact_id });
    if (res?.status) {
      setChats((prevChats: Chatroom[]) => {
        let _chats: Chatroom[] = [];
        if (prevChats && prevChats.length > 0) {
          _chats = prevChats.slice();
          const index = prevChats.findIndex((chat) => contact_id === chat.id);
          if (index > -1) {
            _chats[index].total_unread = -1;
            return _chats;
          }
        }
        return _chats;
      });
    }
  };

  const onCancelGlobalSearch = () => {
    setCurrentHighlightMessage(null);
    setGlobalSearchKeyword('');
    setLastSearchMessageKey('');
    setIsDisableLiveMessages(false);
    setHasNewMessageDuringDisableState(false);
    if (selectedChat?.id) {
      goToLatestMessage(selectedChat?.id);
    }
    globalSearchFormRef?.current?.setFieldValue('search', '');
  };

  const {
    isModalVisible: isSendFlowModalVisible,
    openModal: openSendFlowModal,
    closeModal: closeSendFlowModal,
  } = useModal();

  const {
    isModalVisible: isNoteModalVisible,
    openModal: openNoteModal,
    closeModal: closeNoteModal,
  } = useModal();

  const onClickSearchedMessageDebounce = (messageId: string, selectedChatId: any) => {
    if (lastSearchMessageKey === `${selectedChatId}.${messageId}`) {
      return;
    } else {
      setLastSearchMessageKey(`${selectedChatId}.${messageId}`);
    }
    if (messageId && selectedChatId) {
      showChatDetails(selectedChatId, {
        wa_contact_id: selectedChatId,
        from_message_id: messageId,
      });
    }
  };

  const onClickSearchedMessage = _debounce(onClickSearchedMessageDebounce, 500);

  const onReloadSearchChatroom = async () => {
    setGlobalSearchKeyword((prevGlobalSearchKeyword) => {
      onSearchChatroom(prevGlobalSearchKeyword);
      return prevGlobalSearchKeyword;
    });
  };

  const onSearchChatroom = async (value: string) => {
    setHasMoreMessages(true);
    setGlobalSearchKeyword(value);
    const res = await onSearchChatrooms({
      search: value,
    });
    setIsLoadingSearch(false);
    if (res && res?.length < 15) {
      setHasMoreMessages(false);
    }
    setChats(res || []);
  };

  return {
    chats,
    setChats,
    chatroomFilters,
    setChatroomFilters,
    isLoadingFetchGetChatrooms,
    fetchGetChatrooms,
    reloadFetchGetChatroom,
    selectedChat,
    showChatDetails,
    clearChatDetails,
    isShowAdvancedActions,
    toggleIsShowAdvancedActions: () => {
      localStorage.setItem('is-show-advanced-actions', `${!isShowAdvancedActions}`);
      setAdvancedSection('contact');
      setIsShowAdvancedActions(!isShowAdvancedActions);
    },
    onShowSearchSection: () => {
      localStorage.setItem('is-show-advanced-actions', `${true}`);
      setAdvancedSection('search');
      setIsShowAdvancedActions(true);
    },
    onCloseSearchSection: () => {
      setCurrentHighlightMessage(null);
      localStorage.setItem('is-show-advanced-actions', `${false}`);
      setAdvancedSection('search');
      setIsShowAdvancedActions(false);
    },
    isLoadingFetchMarkChatroomArchiveStatus,
    onMarkChatroomAsUnarchive,
    onMarkChatroomAsArchive,
    updateWithLatestChat,
    isLoadingFetchMarkChatroomAsRead,
    fetchMarkChatroomAsRead,
    isLoadingFetchMarkChatroomAsUnRead,
    fetchMarkChatroomAsUnRead,
    isOpenChatList,
    setIsOpenChatList,
    onAddNewChat,
    onResetChats,
    onGetChatrooms,
    setSelectedChat,
    onMarkAsRead,
    onMarkAsUnRead,
    onMarkAsPin,
    onMarkAsUnPin,
    hasActiveConversationWindow,
    setHasActiveConversationWindow,
    isLoadingFetchMarkChatroomAsPin,
    fetchMarkChatroomAsPin,
    isSendFlowModalVisible,
    openSendFlowModal,
    closeSendFlowModal,
    isNoteModalVisible,
    openNoteModal,
    closeNoteModal,
    advancedSection,
    searchKeyword,
    setSearchKeyword,
    setAdvancedSection,
    globalSearchKeyword,
    setGlobalSearchKeyword,
    globalSearchType,
    setGlobalSearchType,
    onSearchChatrooms,
    onCancelGlobalSearch,
    globalSearchFormRef,
    setCurrentEditNote,
    currentEditNote,
    setIsLiveUpdateChatlist,
    isLiveUpdateChatlist,
    setIsShowNewUpdatesOnChatList,
    isShowNewUpdatesOnChatList,
    onClickSearchedMessage,
    setLastSearchMessageKey,
    onClearFilters,
    isLoadingSearch,
    setIsLoadingSearch,
    hasMoreMessages,
    setHasMoreMessages,
    onSearchChatroom,
    onReloadSearchChatroom,
    isLoadingSearchMessages,
    setIsLoadingSearchMessages,
  };
};
